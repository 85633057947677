import * as _esObjectAtoms2 from "es-object-atoms";
var _esObjectAtoms = _esObjectAtoms2;
try {
  if ("default" in _esObjectAtoms2) _esObjectAtoms = _esObjectAtoms2.default;
} catch (e) {}
var exports = {};
var $Object = _esObjectAtoms;

/** @type {import('./Object.getPrototypeOf')} */
exports = $Object.getPrototypeOf || null;
export default exports;